exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-baum-pflanzen-tsx": () => import("./../../../src/pages/baum-pflanzen.tsx" /* webpackChunkName: "component---src-pages-baum-pflanzen-tsx" */),
  "component---src-pages-baumspende-tsx": () => import("./../../../src/pages/baumspende.tsx" /* webpackChunkName: "component---src-pages-baumspende-tsx" */),
  "component---src-pages-hintergrund-tsx": () => import("./../../../src/pages/hintergrund.tsx" /* webpackChunkName: "component---src-pages-hintergrund-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-karte-tsx": () => import("./../../../src/pages/karte.tsx" /* webpackChunkName: "component---src-pages-karte-tsx" */),
  "component---src-pages-klima-retten-tsx": () => import("./../../../src/pages/klima-retten.tsx" /* webpackChunkName: "component---src-pages-klima-retten-tsx" */),
  "component---src-pages-lebenswald-schenken-tsx": () => import("./../../../src/pages/lebenswald-schenken.tsx" /* webpackChunkName: "component---src-pages-lebenswald-schenken-tsx" */),
  "component---src-pages-neues-post-tsx": () => import("./../../../src/pages/neues-post.tsx" /* webpackChunkName: "component---src-pages-neues-post-tsx" */),
  "component---src-pages-neues-tsx": () => import("./../../../src/pages/neues.tsx" /* webpackChunkName: "component---src-pages-neues-tsx" */),
  "component---src-pages-regenwald-kaufen-tsx": () => import("./../../../src/pages/regenwald-kaufen.tsx" /* webpackChunkName: "component---src-pages-regenwald-kaufen-tsx" */),
  "component---src-pages-sie-machen-den-unterschied-tsx": () => import("./../../../src/pages/sie-machen-den-unterschied.tsx" /* webpackChunkName: "component---src-pages-sie-machen-den-unterschied-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */)
}

