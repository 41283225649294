import { createTheme, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Palette {
        bosGreen: { main: string; dark: string };
        green62: { main: string };
        darkGreen: { main: string };
        brighterGreen: { main: string };
        blueGreen: { main: string };
        boldGreen: { main: string };
        lightGreen: { main: string };
        lightGrey: { main: string };
        white: { main: string };
        bosRed: { main: string; dark: string };
        bosYellow: { main: string };
        bosBrightYellow: { main: string };
    }
    interface PaletteOptions {
        bosGreen: { main: string; dark: string };
        green62: { main: string };
        darkGreen: { main: string };
        brighterGreen: { main: string };
        blueGreen: { main: string };
        boldGreen: { main: string };
        lightGreen: { main: string };
        lightGrey: { main: string };
        white: { main: string };
        bosRed: { main: string; dark: string };
        bosYellow: { main: string };
        bosBrightYellow: { main: string };
    }
}

const { palette } = createTheme();
const theme = responsiveFontSizes(
    createTheme({
        // using the MUI V4 breakpoint values so that the UI doesn't break even tho we are using V5
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        typography: {
            fontFamily: 'Cairo',
            h1: {
                fontSize: '5rem',
                fontWeight: 400,
            },
            h2: {
                fontSize: '3.75rem',
                fontWeight: 400,
            },
            h3: {
                fontSize: '2.5rem',
                fontWeight: 400,
            },
            h4: {
                fontSize: '1.875rem',
            },
            subtitle1: {
                fontSize: '1rem',
            },
            subtitle2: {
                fontSize: '0.875rem',
            },
            body1: {
                fontSize: '1.125rem',
                lineHeight: '1.625',
            },
            // todo: make a nice small typography variant - e.g. to use on mobile map overlays
            // body2: {
            //     fontSize: '12px',
            // },
        },
        palette: {
            bosGreen: palette.augmentColor({
                color: {
                    main: '#548c40',
                    dark: '#447334',
                },
            }),
            primary: {
                main: '#222222',
            },
            // TODO: standardise the greens in the palette. Why are there so many similar ones??
            green62: palette.augmentColor({
                color: {
                    main: '#62ae77',
                },
            }),
            darkGreen: palette.augmentColor({ color: { main: '#002b1b' } }),
            brighterGreen: palette.augmentColor({ color: { main: '#08662e' } }),
            blueGreen: palette.augmentColor({ color: { main: '#065b52' } }),
            boldGreen: palette.augmentColor({ color: { main: '#7fbc4f' } }),
            lightGreen: palette.augmentColor({ color: { main: '#b8cfa7' } }),
            lightGrey: palette.augmentColor({ color: { main: '#A9A9A9' } }),
            white: palette.augmentColor({ color: { main: '#fff' } }),
            bosRed: palette.augmentColor({ color: { main: '#C84932', dark: '#8D2C1A' } }),
            bosYellow: palette.augmentColor({ color: { main: '#f7de8f' } }),
            bosBrightYellow: palette.augmentColor({ color: { main: '#f7c72e' } }),
        },
    })
);

export default theme;
