import React from 'react';
import type { GatsbyBrowser } from "gatsby"
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './src/components/theme';
import { Context } from './src/context/index';

// Context contains the Provider components it composes our
// Providers, respectively passing down state and dispatch thus
// giving anything within the app access to them.
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
    <StyledEngineProvider>
        <ThemeProvider theme={theme}>
            <Context>
                {element}
            </Context>
        </ThemeProvider>
    </StyledEngineProvider>
);
