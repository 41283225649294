import { create } from 'zustand';
import { LocaleEnum, LocaleStoreInterface } from '../interfaces';

const defaultLanguage = LocaleEnum['de-DE'];
/* let defaultLanguage = LocaleEnum['de-DE'];
// check for the browser's setting to use as the default
if (navigator.language.includes('en')) {
    defaultLanguage = LocaleEnum.en;
} */

const useLocaleStore = create<LocaleStoreInterface>(set => ({
    localeStore: defaultLanguage,
    setLocaleStore: localeStore => set(() => ({ localeStore })),
}));

export default useLocaleStore;
