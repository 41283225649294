import React, { cloneElement } from 'react';
import { Donation } from './donation';
import { Grid } from './grid';
import { SelectedNews } from './selectedNews';
import { User } from './user';

const providers = [
    <Donation.Provider />,
    <User.Provider />,
    <SelectedNews.Provider />,
    <Grid.Provider />,
];

const Context = function ({ children: initial }) {
    return providers.reduce((children, parent) => cloneElement(parent, { children }), initial);
};

export { Context, Donation, Grid, SelectedNews, User };
