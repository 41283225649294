import React, { createContext, useContext, useReducer, useMemo, useEffect, useState } from 'react';
import center from '@turf/center';
import distance from '@turf/distance';
import { User } from './user';
import { Grid } from './grid';

// Context
const State = createContext();
const Dispatch = createContext();

// Provider
const Provider = function Provider({ children }) {
    const grid = useContext(Grid.State);
    const { userStats } = useContext(User.State);
    const [selStartCellID, setSelStartCellID] = useState();
    // precompute suggestedCells
    const suggestedCells = useMemo(() => {
        // performance.mark('preComputeSuggestedCells');
        if (!grid.length) return [];
        const opens = grid.filter(obj => obj.status === 'open');
        const protecteds = grid.filter(
            obj =>
                obj.status === 'preprotected' ||
                obj.status === 'protected' ||
                obj.status === 'temporary'
        );
        let selCenter = protecteds;
        // center around user cells if present
        if (userStats && userStats.cellsOwned.length) {
            selCenter = selCenter.filter(obj => userStats.cellsOwned.includes(obj.id));
        }
        // if selection start cell is present, center around that
        if (selStartCellID) {
            selCenter = opens.filter(i => i.id === selStartCellID);
        }
        const tileCenter = center({
            type: 'FeatureCollection',
            features: selCenter,
        });
        const result = opens
            .map(i => ({ ...i, dist: distance(i.geometry, tileCenter) }))
            .sort((a, b) => a.dist - b.dist)
            .map(i => i.id);
        // performance.measure('donation.tsx: precompute suggestedCells', 'preComputeSuggestedCells');
        return result;
    }, [selStartCellID]);

    // Reducer
    const reducer = (state, action) => {
        // TODO check for 0 euro value and decrease so as not to go into negatives
        // donation >= 5 ?
        //     setDonation(donation - 5)
        // : console.log("too small to subtract")}
        switch (action.type) {
            case 'setDonationState':
                setSelStartCellID(action.val.selectedCellIDs[0]);
                return {
                    ...state,
                    euros: action.val.euros,
                    selectedCellIDs: action.val.selectedCellIDs,
                };
            case 'changeByValue': {
                // if positive we need to allocate, if negative we need to de-allocate cells
                const nToAllocate = parseInt(action.val) / 5 - state.selectedCellIDs.length;
                if (nToAllocate > 0) {
                    // ToDo: slow operation, replace state.selectedCellIDs with a Set instead of Array
                    const suggested = suggestedCells
                        // take sufficiently large sample
                        .slice(0, nToAllocate + state.selectedCellIDs.length)
                        // filter selectedCellIDs, could be sped up with Set().has #ToDo
                        .filter(id => !state.selectedCellIDs.includes(id))
                        .slice(0, nToAllocate);
                    return {
                        ...state,
                        euros: parseInt(action.val),
                        selectedCellIDs: state.selectedCellIDs.concat(suggested),
                    };
                } else if (nToAllocate < 0) {
                    const newSel = state.selectedCellIDs.slice(
                        0,
                        state.selectedCellIDs.length + nToAllocate
                    );
                    // if selection start cell gets deselected, choose new one
                    if (!newSel.includes(selStartCellID)) {
                        setSelStartCellID(newSel[0]);
                    }
                    return {
                        ...state,
                        euros: parseInt(action.val),
                        selectedCellIDs: newSel,
                    };
                } else if (Number.isNaN(nToAllocate)) {
                    //  handle if value is null or empty string - this is needed so that the user can select nothing in a text input so they can write whatever number they want
                    return {
                        ...state,
                        euros: '',
                        selectedCellIDs: [],
                    };
                } else return state;
            }
            case 'gridClick':
                const euroVal = state.euros || 0; // handle if the euros value was an empty string (this is needed so the input is nullable)
                // check if the cell is already selected
                if (state.selectedCellIDs.includes(action.val)) {
                    // cell is selected so now we deselect it
                    const updatedSel = state.selectedCellIDs.filter(e => e !== action.val);
                    return {
                        ...state,
                        euros: euroVal - 1 * 5,
                        selectedCellIDs: updatedSel,
                    };
                } else {
                    // else cell is not selected
                    const found = grid.find(el => el.id === action.val);
                    if (found && found.status === 'open') {
                        setSelStartCellID(found.id);
                        return {
                            ...state,
                            euros: euroVal + 1 * 5,
                            selectedCellIDs: state.selectedCellIDs.concat([action.val]),
                        };
                    } else {
                        console.log('Grid Error'); // the grid is not matching the DB
                        return {
                            ...state,
                        };
                    }
                }
            default:
                console.log('Bad Action Type');
        }
    };

    const [state, dispatch] = useReducer(reducer, { euros: 0, selectedCellIDs: [] });

    // this effect updates auto-selected cells when the grid or user's cells owned change
    useEffect(() => {
        // abort if empty grid because the grid must be populated for this to work
        if (!grid || !grid.length) return;

        // performance.mark('updateAutoselection');
        // the auto-selection
        const numberToSelect = state.euros / 5 || 5; // if there is a certain number of euros selected, use that number / 5 (because each cell costs €5) as the amount to select. Otherwise select 5 cells.
        // performance.mark('categoriseGrid');
        const opens = grid.filter(obj => obj.status === 'open'); // a list of cell IDs that are open for purchase
        // a list of cell IDs that are already purchased (or temporary which means the transaction is in progress in the backend)
        const protecteds = grid.filter(
            obj =>
                obj.status === 'preprotected' ||
                obj.status === 'protected' ||
                obj.status === 'temporary'
        );
        let selCenter = protecteds; // a list of cells ids that default to the protected cells
        // center around user cells if present
        if (userStats && userStats.cellsOwned.length) {
            selCenter = selCenter.filter(obj => userStats.cellsOwned.includes(obj.id));
        }
        // performance.measure('donation.tsx: update auto-selected cells: prepwork, categoriseGrid', 'categoriseGrid');

        // here the center of mass of the user's cells is calculated. From that a number of nearby cells are added or removed depending on what is needed.
        // performance.mark('centerOfMass');
        const tileCenter = center({ type: 'FeatureCollection', features: selCenter });
        // performance.measure('donation.tsx: update auto-selected cells: centerOfMass', 'centerOfMass');
        // performance.mark('distanceToCenter');
        const suggested = opens
            .map(i => ({ ...i, dist: distance(i.geometry, tileCenter) }))
            .sort((a, b) => a.dist - b.dist)
            .map(i => i.id)
            .slice(0, numberToSelect);
        // performance.measure('donation.tsx: update auto-selected cells: distanceToCenter', 'distanceToCenter');
        dispatch({
            type: 'setDonationState',
            val: {
                euros: numberToSelect * 5,
                selectedCellIDs: suggested,
            },
        });
        // performance.measure('donation.tsx: update auto-selected cells', 'updateAutoselection');
    }, [grid, userStats.cellsOwned]);

    return (
        <State.Provider value={state}>
            <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
        </State.Provider>
    );
};

// Export
export const Donation = {
    State,
    Dispatch,
    Provider,
};
