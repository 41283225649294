import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { Cookies } from 'react-cookie-consent';
import { Grid } from './grid';
import { getHashFromEmail, getLastTransaction } from '../utils/helpers';

// Context
const State = createContext();
const Dispatch = createContext();

const defaultUserStats = {
    cellsOwned: [],
    cellsOwnedBBox: null,
    numberOfCellsOwned: 0,
    CO2saved: 0,
    CO2savedString: '0\u200Ag',
};

// Reducer
const reducer = (state, action) => {
    switch (action.type) {
        case 'updateUserStats':
            return {
                ...state,
                userStats: action.val,
            };
        case 'manualInput':
            return {
                ...state,
                userInput: action.val,
            };
        case 'nothingFound':
            return {
                ...state,
                // userInput: null,
                userStats: defaultUserStats,
                lastTransaction: [],
            };
        case 'clearInput':
            return {
                ...state,
                userInput: null,
                userStats: defaultUserStats,
                lastTransaction: [],
            };
        case 'setLastTransaction':
            return {
                ...state,
                lastTransaction: action.val,
            };
        default:
            return state;
    }
};

export function calcCO2savedVal(cellsOwned) {
    const currentDate = new Date();
    // How many months passed since tile was claimed? CO2 saved is 940g/month/tile
    // 30.41 days * 24h * 60min * 60s * 1000ms = 2627424000ms in a month
    // if claimedDate is not a number, set to current date. Comes in seconds, hence * 1000
    return cellsOwned
        .map(i => {
            let claimedDate;
            if (Number(i.claimedDate)) {
                claimedDate = i.claimedDate * 1000;
            } else {
                claimedDate = currentDate;
            }
            // convert to grams
            return Math.floor(((currentDate - claimedDate) / 2627424000) * 940);
        })
        .reduce((a, b) => a + b, 0);
}

export function calcCO2savedString(CO2saved) {
    let CO2savedString;
    if (CO2saved > 10000000) {
        // over 10t, switch to tons
        CO2savedString = `${(CO2saved / 1000000).toFixed(1)}\u200At`;
    } else if (CO2saved > 10000) {
        // over 10kg, switch to kg
        CO2savedString = `${(CO2saved / 1000).toFixed(1)}\u200Akg`;
    } else CO2savedString = `${CO2saved}\u200Ag`;
    return CO2savedString;
}

function calcOwnedBBox(cellsOwned) {
    let cellsOwnedBBox = null;
    if (cellsOwned.length > 0) {
        const lons = cellsOwned.map(a => a.geometry.coordinates[0]);
        const lats = cellsOwned.map(a => a.geometry.coordinates[1]);
        const lonMax = Math.max(...lons);
        const lonMin = Math.min(...lons);
        const latMax = Math.max(...lats);
        const latMin = Math.min(...lats);
        cellsOwnedBBox = [
            [lonMin, latMin],
            [lonMax, latMax],
        ];
    }
    return cellsOwnedBBox;
}

// Provider
const Provider = function Provider({ children }) {
    const grid = useContext(Grid.State);
    const [state, dispatch] = useReducer(reducer, {
        userInput: null,
        userStats: defaultUserStats,
        lastTransaction: [],
    });

    useEffect(() => {
        // calculate user stats when the grid changes or the user input changes
        async function calcUserStatsAsync() {
            if (grid && grid.length && state.userInput) {
                const isNum = /^\d+$/.test(state.userInput); // check if the user input is just a number. If it is then it is a transaction_id, otherwise it is an email
                let cellsOwned = [];
                let lastTransaction = [];
                if (isNum) {
                    console.log('user input a transaction_id');
                    const userInputTIDHash = await getHashFromEmail(`FB-T-${state.userInput}`); // the user input hash - transaction ID
                    cellsOwned = grid.filter(i => {
                        return i.fboxTransactionIdHash === userInputTIDHash;
                    });
                } else {
                    console.log('user input an email');
                    const userInputHash = await getHashFromEmail(state.userInput); // the user input hash
                    const userInputLCHash = await getHashFromEmail(state.userInput.toLowerCase()); // the user input hash - lower case
                    cellsOwned = grid.filter(i => {
                        return i.ownerHash === userInputHash || i.ownerHash === userInputLCHash;
                    });
                }
                // if tiles are found then set the cookie (this is essential functionality so the consent doesn't matter) and calc the stats
                if (cellsOwned.length) {
                    Cookies.set('userInput', state.userInput);
                    // if the user has not accepted the site to use cookies then we don't set one.
                    // This means they will have to reenter their email or transaction ID

                    // set the user url param to the submitted value
                    const url = new URL(window.location);
                    if (url.searchParams.has('userInput')) {
                        url.searchParams.set('userInput', state.userInput);
                    } else {
                        url.searchParams.append('userInput', state.userInput);
                    }
                    // window.location = url;
                    window.history.pushState({}, null, url);
                    const CO2saved = calcCO2savedVal(cellsOwned);
                    const CO2savedString = calcCO2savedString(CO2saved);
                    const cellsOwnedBBox = calcOwnedBBox(cellsOwned);

                    dispatch({
                        type: 'updateUserStats',
                        val: {
                            cellsOwned: cellsOwned.map(a => a.id),
                            cellsOwnedBBox,
                            numberOfCellsOwned: cellsOwned.length,
                            CO2saved,
                            CO2savedString,
                        },
                    });

                    lastTransaction = getLastTransaction(cellsOwned);
                    dispatch({
                        type: 'setLastTransaction',
                        val: {
                            lastTransaction,
                        },
                    });
                } else {
                    // remove the cookie
                    const cookies = Cookies.get();
                    if (cookies.userInput) {
                        // to remove a cookie you must set it to have already expired
                        document.cookie = 'userInput=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    }

                    // remove the user url param
                    const url = new URL(window.location);
                    if (url.searchParams.has('userInput')) {
                        url.searchParams.delete('userInput');
                        window.history.pushState({}, null, url);
                    }

                    // nothing was found so dispatch empty
                    dispatch({
                        type: 'nothingFound',
                    });
                }
            }
        }
        calcUserStatsAsync();
    }, [grid, state.userInput]);

    return (
        <State.Provider value={state}>
            <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
        </State.Provider>
    );
};

// Export
export const User = {
    State,
    Dispatch,
    Provider,
};
