import React, { createContext, useReducer } from 'react';

// Context
const State = createContext();
const Dispatch = createContext();

// Reducer
const reducer = (state, action) => action;

// Provider
const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, null);
    return (
        <State.Provider value={state}>
            <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
        </State.Provider>
    );
};

// Export
export const SelectedNews = {
    State,
    Dispatch,
    Provider,
};
