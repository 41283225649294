import mapboxgl, { Map } from 'mapbox-gl';
import { GridInterface, LocaleEnum, GenericObject } from '../interfaces';
import useLocaleStore from '../store/localeStore';

// performance measurements
/*
// commented because it breaks Firefox
export const perfMeasurements = {};
if (typeof window !== 'undefined') {
    const obs = new PerformanceObserver(list => {
        for (const e of list.getEntries()) {
            if (perfMeasurements[e.name]) {
                perfMeasurements[e.name].push(e);
            } else {
                perfMeasurements[e.name] = [e];
            }
        }
    });
    obs.observe({ entryTypes: ['measure'], buffered: true });
    performance.mark('start');
}
*/

// nice string of trees in the correct lang
export function treesPlantedString(numberOfCells: number, lang: string): string {
    if (numberOfCells === 1 && lang === 'de-DE') {
        return '1 Baum';
    } else if (lang === 'de-DE') {
        return `${numberOfCells} Bäume`;
    } else if (numberOfCells === 1 && lang === 'en') {
        return '1 Tree';
    } else if (lang === 'en') {
        return `${numberOfCells} Trees`;
    } else {
        console.error('Bad data');
        return `${numberOfCells} Trees`;
    }
}

export function generatePopupHTML(
    localeContent: {
        popupGiftText1: string;
        popupGiftText2: string;
        popupGiftStats1: string;
        popupGiftStats2: string;
        popupGiftStats3: string;
        popupGiftStats4: string;
        popupOtherUserText1: string;
        popupOtherUserText2: string;
        popupOtherUserStats1: string;
        popupOtherUserStats2: string;
        popupOtherUserStats3: string;
        popupOtherUserStats4: string;
    },
    treeString: string,
    featureState: { giftFirstName: string; firstName: string },
    lang: 'de' | 'en',
    CO2savedString: string
) {
    let popupHTML = '';
    // replace the missing names
    const replacementName = lang === 'de' ? 'Anonyme*r Waldspender*in' : 'Anonymous Donor';
    const name: string =
        !featureState.firstName || featureState.firstName === 'Unbekannte(r) Waldspender(in)'
            ? replacementName
            : featureState.firstName;
    const giftName: string =
        !featureState.giftFirstName ||
        featureState.giftFirstName === 'Unbekannte(r) Waldspender(in)'
            ? replacementName
            : featureState.giftFirstName;
    // eslint-disable-next-line no-lonely-if
    if (featureState.giftFirstName) {
        // gifted cells
        if (lang === 'de') {
            // gifted cells - German
            // add gift name to popup
            popupHTML = `<p>${localeContent.popupGiftText1} ${giftName} ${localeContent.popupGiftText2} ${name}.</p>`;
            // add statistics to popup
            popupHTML += `${localeContent.popupGiftStats1} ${giftName} ${treeString} ${localeContent.popupGiftStats2} ${CO2savedString} ${localeContent.popupGiftStats3}`;
        } else if (lang === 'en') {
            // gifted cells - English
            // add gift name to popup
            popupHTML = `<p>${localeContent.popupGiftText1} ${giftName}. ${localeContent.popupGiftText2} ${name}.</p>`;
            // add statistics to popup
            popupHTML += `${localeContent.popupGiftStats1} ${giftName} ${localeContent.popupGiftStats4} ${treeString}${localeContent.popupGiftStats2} ${CO2savedString} ${localeContent.popupGiftStats3}`;
        }
    } else if (lang === 'de') {
        // not a gifted cell in German
        // add name to popup
        popupHTML = `<p>${localeContent.popupOtherUserText1} ${name} ${localeContent.popupOtherUserText2}</p>`;
        // add statistics to popup
        popupHTML += `${localeContent.popupOtherUserStats1} ${name} ${localeContent.popupOtherUserStats4} ${treeString} ${localeContent.popupOtherUserStats2} ${CO2savedString} ${localeContent.popupOtherUserStats3}`;
    } else if (lang === 'en') {
        /// not a gifted cell in English
        // add name to popup
        popupHTML = `<p>${localeContent.popupOtherUserText1} ${name}.</p>`;
        // add statistics to popup
        popupHTML += `${localeContent.popupOtherUserStats1} ${name} ${localeContent.popupOtherUserStats4} ${treeString} ${localeContent.popupOtherUserStats2} ${CO2savedString} ${localeContent.popupOtherUserStats3}`;
    }

    return popupHTML;
}

const base64chars = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/');

const currentTime = Date.now();
// get colour by status and ownerHash
export function getColour(status: string, ownerHash?: string, claimedDate?: number) {
    if (status === 'protected' || status === 'preprotected' || status === 'temporary') {
        if (!claimedDate) {
            console.error('bad seachInputs');
            return 'hsla(0, 0%, 0%, 0)';
        }
        let ownerShift = 0;
        if (ownerHash) {
            // we leverage the uniformity of the SHA256 char distribution
            // to get a semirandom integer from the ownerhash
            // look up index of first two owner hash chars
            ownerShift = base64chars.indexOf(ownerHash.slice(0, 1));
            // normalize to [0, 1]
            ownerShift /= base64chars.length;
        }
        // calculate lightness shift by age of cell; 159922.. is the epoch of first donation
        let ageShift = 0;
        if (claimedDate && currentTime) {
            const relativeAge =
                (currentTime / 1000 - claimedDate) / (currentTime / 1000 - 1599224726);
            ageShift = 1 - relativeAge;
        }
        return `hsla(${135 + ownerShift * 8},
                             ${30 + ageShift * 40 + ownerShift * 20}%,
                             ${28 + ageShift * 19 + ownerShift * 10}%, 1)`;
    } else if (
        status === 'open' ||
        status === 'usercell' ||
        status === 'userLastTransaction' ||
        status === 'selected'
    ) {
        const colourMap = {
            open: 'hsla(0,0%,100%,0.2)',
            userLastTransaction: 'hsla(300, 100%, 50%, 1)',
            usercell: 'hsla(275, 100%, 50%, 1)',
            selected: 'hsla(20, 100%, 65%, 0.8)',
        };
        return colourMap[status] || 'hsla(0, 0%, 0%, 0)';
    } else {
        console.error('bad inputs');
        return 'hsla(0, 0%, 0%, 0)';
    }
}

// This function must be called asynchronously
export async function getHashFromEmail(str: string): Promise<string> {
    // Get the string as arraybuffer.
    const buffer = new TextEncoder('utf-8').encode(str);
    return crypto.subtle.digest('SHA-256', buffer).then(hash => {
        return btoa(String.fromCharCode(...new Uint8Array(hash)));
    });
}

// This function gets all the cells belonging to a given user and outputs only the cells
// with the same date as the most recent cell.
export function getLastTransaction(userCells: GridInterface) {
    // Find the most recent date
    const mostRecentDate = userCells.reduce((mostRecent, current) => {
        // converting milliseconds to seconds since epoch
        const currentClaimedDate = new Date(current.claimedDate * 1000);
        return currentClaimedDate > mostRecent ? currentClaimedDate : mostRecent;
    }, new Date(0)); // Initialize with a very old date waz before cells were able to be claimed

    // Filter items that have the same date as the most recent date
    const sameDayCells = userCells.filter(item => {
        // converting milliseconds to seconds since epoch
        const itemDate = new Date(item.claimedDate * 1000);
        return (
            itemDate.getDate() === mostRecentDate.getDate() &&
            itemDate.getMonth() === mostRecentDate.getMonth() &&
            itemDate.getFullYear() === mostRecentDate.getFullYear()
        );
    });
    // if the cell is the only cell that was claimed on a certain day,
    // then it is the only cell in the array returned
    return sameDayCells;
}

// This function create a popup from the information provided
export function addPopup(coordinates: [number, number], popupContent: string, mapboxGlMap: Map) {
    new mapboxgl.Popup().setLngLat(coordinates).setHTML(popupContent).addTo(mapboxGlMap);
}

// Clear Popups
export function removePopup() {
    const popups = document.getElementsByClassName('mapboxgl-popup');
    if (popups.length) {
        popups[0].remove();
    }
}

export function localeContentExtractor(
    pageQuery: GenericObject,
    key: string,
    onlyOne: boolean,
    englishPosts?: boolean,
    slugCheck?: string | null,
    pageName?: boolean
) {
    const localeStore = useLocaleStore(state => state.localeStore);
    let localeContent = pageQuery[key].edges.filter(p => p.node.node_locale === localeStore);
    // filter out germanOnly posts
    if (englishPosts && localeStore === LocaleEnum.en)
        localeContent = localeContent.filter(p => p.node.germanOnly !== true);
    if (slugCheck) localeContent = localeContent.filter(p => p.node.slug === slugCheck);
    if (pageName && localeStore === LocaleEnum.en)
        localeContent = localeContent.filter(p => p.node.pageName === 'Gift forest');
    if (pageName && localeStore === LocaleEnum['de-DE'])
        localeContent = localeContent.filter(p => p.node.pageName === 'Wald schenken');
    if (onlyOne) return localeContent[0].node;
    else return localeContent;
}

// Deactivate Zeitreise
export function deactivateZeitreise(statefulMap: Map | null) {
    if (!statefulMap) return;
    // revert to the initial opacity settings
    statefulMap?.setPaintProperty('drone-2020', 'raster-opacity', 0);
    statefulMap?.setPaintProperty('drone-2021', 'raster-opacity', 0);
    statefulMap?.setPaintProperty('drone-2022', 'raster-opacity', 0);
    statefulMap?.setPaintProperty('drone-2023', 'raster-opacity', 1);
    // Unhide the grid when the Zeitreise is turned off
    statefulMap?.setLayoutProperty('gridIcon', 'visibility', 'visible');
    statefulMap?.setLayoutProperty('grid', 'visibility', 'visible');
    statefulMap?.setLayoutProperty('gridLine', 'visibility', 'visible');

    statefulMap?.setLayoutProperty('zeitreiseGrid', 'visibility', 'none');
}
