import { Map, LngLatLike, LngLatBoundsLike } from 'mapbox-gl';

export enum LocaleEnum {
    'de-DE' = 'de-DE',
    'en' = 'en',
}

// export type LocaleType = string;
export interface LocaleStoreInterface {
    localeStore: LocaleEnum;
    setLocaleStore: (mapStore: LocaleEnum) => void;
}

export type GenericObject = { [key: string]: any };

export type GridStatus = 'open' | 'closed' | 'protected' | 'preprotected' | 'temporary';

export type numberSetInterface = React.Dispatch<React.SetStateAction<number>>;
export type stringSetInterface = React.Dispatch<React.SetStateAction<string>>;
export type booleanSetInterface = React.Dispatch<React.SetStateAction<boolean>>;

export interface UserStatsInterface {
    cellsOwned: number[];
    cellsOwnedBBox: LngLatBoundsLike;
    numberOfCellsOwned: number;
    CO2saved: number;
    CO2savedString: string;
}
export interface UserInterface {
    userInput: string | null;
    userStats: UserStatsInterface;
    lastTransaction: string[];
}

export interface GridInterfaceSingle {
    id: string;
    status: GridStatus;
    claimedDate: number; // milliseconds since epoch?
    firstName: string;
    giftFirstName: string;
    ownerHash: string;
    fboxTransactionIdHash: string;
    geometry: {
        type: 'Point';
        coordinates: LngLatLike; // more specifically it is: [number, number];
    };
}
export type GridInterface = GridInterfaceSingle[];

export type MapType = Map | null;

export type ZeitreiseMode = 'play' | 'pause' | 'end';
export type ZeitreiseModeSetInterface = React.Dispatch<React.SetStateAction<ZeitreiseMode>>;

export type SliderValType = number | null;
export type SliderValTypeSetInterface = React.Dispatch<React.SetStateAction<SliderValType>>;
