import React, { createContext, useReducer } from 'react';

// Context
const State = createContext();
const Dispatch = createContext();

// Reducer
const reducer = (state, action) => action;

const updateGrid = function updateGrid(gridDispatcher) {
    const URL = process.env.GATSBY_CACHE_URL;
    fetch(URL)
        .then(response => {
            if (!response.ok) {
                return response.text().then(res => {
                    const err = new Error('Network response was not ok.');
                    err.responseText = res;
                    err.responseObj = response;
                    throw err;
                });
            }
            return response.json();
        })
        .then(data => {
            // performance.mark('start_parsingGridCache');
            // collect protected IDs for stale cache protection
            const grid = data.map(({ x, y, ...i }) => {
                return {
                    ...i,
                    geometry: { type: 'Point', coordinates: [x, y] },
                };
            });
            gridDispatcher(grid);
            // performance.measure('grid.tsx: parsing grid cache', 'start_parsingGridCache');
        })
        .catch(error => {
            console.error('Error fetching tile cache:\n', error);
        });
};

// Provider
function Provider({ children }) {
    const [state, dispatch] = useReducer(reducer, []);
    return (
        <State.Provider value={state}>
            <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
        </State.Provider>
    );
}

// Export
export const Grid = {
    State,
    Dispatch,
    Provider,
    updateGrid,
};
